<template>
  <div>
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link class="alert-link" :to="{ name: 'clients-list' }"> Client List </b-link>
        for other clients.
      </div>
    </b-alert>
    <div v-if="clientData && Object.keys(clientData).length > 0">
      <b-card no-body class="p-1 px-2">
        <!-- Client Info: Top col -->
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap">
            CLIENT INFORMATION
          </h4>
          <div class="d-flex detail-top-b-list flex-wrap justify-content-end">
            <b-button
              v-if="ability.can('update', 'client_account')"
              class="px-3"
              :to="{ name: 'clients-edit', params: { id: clientData.id } }"
              variant="primary"
              ><feather-icon class="mr-1" size="14" icon="Edit2Icon" />Edit</b-button
            >
            <b-button
              class="px-3"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              class="px-3"
              variant="outline-danger"
              v-if="ability.can('delete', 'client_account')"
              @click="deleteClient(clientData.id)"
              ><feather-icon class="mr-1" size="14" icon="Trash2Icon" />Delete</b-button
            >
          </div>
        </div>
        <hr />
        <!-- Client Table: Left col -->
        <b-row class="info-wrap px-1">
          <b-col title="name(en)" cols="12" sm="12" md="4">
            <h4 class="mb-0 font-weight-bolder">{{ clientData.name_en }}</h4>
          </b-col>

          <b-col title="email" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="MailIcon" />
            {{ clientData.email }}
          </b-col>

          <b-col title="is active?" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="UserCheckIcon" />
            <template>
              <feather-icon v-if="clientData.is_active" size="14" icon="CheckIcon" />
              <feather-icon v-else size="14" icon="XIcon" />
            </template>
          </b-col>
          <hr class="w-100 d-md-none" />
          <b-col title="name(cn)" cols="12" sm="6" md="4">
            <h5 class="mb-0 font-weight-bolder">{{ clientData.name_cn }}</h5>
          </b-col>

          <b-col title="Contact Number" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="PhoneIcon" />
            {{ clientData.contact_number }}
          </b-col>

          <b-col title="HK ID" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="CreditCardIcon" />
            {{ clientData.hkid }}
          </b-col>

          <b-col cols="12" sm="6" md="4">
            Opened Date:
            {{ clientData.open_date }}
          </b-col>

          <b-col title="Fax" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="PrinterIcon" />
            {{ clientData.fax }}
          </b-col>

          <b-col title="Date of Birth" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="CalendarIcon" />
            {{ clientData.date_of_birth }}
          </b-col>

          <b-col cols="12" sm="6" md="4">
            JVSakk A/C No.:
            {{ clientData.account_number }}
          </b-col>
          <hr class="w-100 d-md-none" />
          <b-col title="Address" cols="12" sm="8">
            <feather-icon class="mr-1" size="14" icon="MapPinIcon" />
            {{ clientData.address }}
          </b-col>

          <b-col cols="12">
            Authorised Users:
            {{ clientData.name_en }}
          </b-col>

          <!-- <b-col cols="12 tag-list">
            <feather-icon class="mr-1 color1" size="14" icon="UserIcon" />
            <a class="color1">證劵,</a>
            <a class="color1">外匯,</a>
            <a class="color1">美股</a>
          </b-col> -->
        </b-row>
      </b-card>

      <b-row>
        <b-col md="6">
          <b-card>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 ml-0 color4 font-weight-bolder">CUSTODIAN BANK</h4>
              <!-- <div class="form-col-select mr-1">
                <v-select
                  style="width: 130px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="page"
                  :options="fakeSortPage"
                  placeholder="Sort by"
                  class="per-page-selector d-inline-block mx-50"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div> -->
            </div>
            <hr />

            <div class="mt-3" v-for="bank of clientData.banks" :key="bank.account_number">
              <h5 class="color1 font-weight-bolder">
                {{ bank.bank.name }}
              </h5>
              <p>
                Account No.: <span>{{ bank.account_number }}</span>
              </p>
              <p>
                Investment Program: <span>{{ clientData.remarks }}</span>
              </p>
              <div class="d-flex flex-wrap justify-content-between">
                <div class="cursor-pointer" @click.prevent.stop="triggerSelectBank(bank)">
                  <span class="color3 text-decoration-underline">
                    View Bank Statement & Summary Report
                  </span>
                  <feather-icon size="18" class="color3" icon="ArrowRightIcon" />
                </div>
              </div>
              <!-- Can use function handleDownload to download document -->
            </div>
          </b-card>
        </b-col>
        <b-col v-if="selectedBankAc" md="6">
          <b-card id="downloadBankWrapper">
            <div>
              <h4 class="">{{ selectedBankAc.bank.name }}</h4>
              <p>
                Account No.:<span>{{ selectedBankAc.account_number }}</span>
              </p>
            </div>
            <hr />
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="font-weight-bolder">Download PDF</h5>
              <div class="form-col-select">
                <v-select
                  style="min-width: 150px"
                  v-model="category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="documentCategoryOptions"
                  :clearable="false"
                  label="category_name"
                  :reduce="(option) => option.id"
                  placeholder="Sort by "
                  class="per-page-selector d-inline-block mx-50"
                  @input="(item) => setDocumentFormation(item)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </div>
            <div class="mt-1" v-if="formationDocuments.length > 0">
              <div
                class="mb-1"
                v-for="(docu, index) of formationDocuments"
                :key="'docu' + docu.year + index"
              >
                <div
                  @click="parseInt(collapse) >= 0 ? (collapse = '') : (collapse = index)"
                  v-b-toggle="'collapse-' + index"
                  class="drop-btn"
                >
                  <div class="form-col-select">
                    {{ docu.year }}
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                </div>
                <b-collapse :id="'collapse-' + index">
                  <draggable v-model="docu.data" @start="drag = true" @end="drag = false">
                    <transition-group>
                      <a
                        v-for="(item, index2) of docu.data"
                        href="javascript:void(0)"
                        class="drop-item"
                        :key="'docuItem' + item.id + index2"
                        @click="handleDownload(item)"
                      >
                        {{ item.original_name }}
                      </a>
                    </transition-group>
                  </draggable>
                  <b-button
                    v-if="ability.can('update', 'client_account')"
                    variant="primary"
                    @click="updateSortOrder(docu.data)"
                    style="margin-left: auto"
                  >
                    <feather-icon class="mr-1" size="14" icon="CheckSquareIcon" />Submit
                    New List Order</b-button
                  >
                </b-collapse>
              </div>
            </div>
            <div class="mt-1" v-else>
              <p>No matching data found</p>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import clientUserStoreModule from "../clientUserStoreModule";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    draggable,
    vSelect,
  },
  data() {
    return {
      ability,
      // selectBankAc: "",
      selectedBankAc: "",
      page: "",
      collapse: "",
      years: [2022, 2021, 2020],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      formationDocuments: [],
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  methods: {
    updateSortOrder(doc) {
      let data = { data: doc };
      console.log(data);
      this.$store
        .dispatch("app-client/updateSort", data)
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.selectedBankAc = "";
          this.refetchData();
        })
        .catch((error) => {
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    hide() {
      this.$router.replace("/account");
    },
    handleDownload(item) {
      //this.$store.dispatch(this.DOCUMENT_APP_STORE_MODULE_NAME+'/fetchDocument', {id})
      axiosIns
        .get("/document", { params: { id: item.id }, responseType: "blob" })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", item.original_name); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    deleteClient(id) {
      this.$swal({
        title: "Are you sure?",
        text:
          "Your action is final and you will not be able to retrieve the client record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-client/deleteClient", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectedBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deleteBank(id) {
      if (this.clientData.banks.length <= 1) {
        this.makeToast("danger", "Warning", "Please submit at least one bank record.");
        return;
      }

      this.$swal({
        title: "Are you sure?",
        text:
          "Your action is final and you will not be able to retrieve the custodian bank record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-client/deleteBank", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectedBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    triggerSelectBank(bank) {
      this.selectedBankAc = bank;
      this.setDocumentFormation(this.category_id);
      if (window.innerWidth <= 768) {
        setTimeout(function () {
          const y =
            document.getElementById("downloadBankWrapper").getBoundingClientRect().top +
            window.pageYOffset -
            80;
          window.scrollTo({ top: y, behavior: "smooth" });
        }, 100);
      }
    },

    setDocumentFormation(id) {
      console.log("id", id);
      let original_year = "",
        count = 0;
      this.formationDocuments = [];

      const newArray = this.selectedBankAc.documents.filter(
        (item) => id == item.document_category_id
      );
      newArray.forEach((item, index) => {
        let year = item.file_date.slice(0, 4);
        if (original_year != year) {
          this.formationDocuments.push({ year: year, data: [] });
          original_year = year;
          if (index > 0) {
            count++;
          }
        }

        this.formationDocuments[count].data.push(item);
      });
    },
  },
  setup() {
    const clientData = ref({});

    const CLIENT_APP_STORE_MODULE_NAME = "app-client";

    const client = {
      id: 0,
      account_number: "",
      name_cn: "",
      name_en: "",

      account_type_id: [],
      is_company: 0,
      banks: [{ id: 0, bank_id: null, account_number: "" }],
      controlling_persons: [
        {
          name: "",
          email: "",
          identity_document: "",
          date_of_birth: null,
          person_type_id: null,
        },
      ],
    };

    const demoClient = {
      id: 0,
      name_cn: "PAPER ONE Ltd",
      name_en: "PAPER ONE Ltd",
      account_type_id: [],
      is_company: 0,
      account_status_id: parseInt(1),
      address: "Flat A, 10/F, Tsuen King Wai Building, Tsuen Wan, KLN",
      open_date: "2022-10-06",
      authorized_persons: [2, 5],
      date_of_birth: "1980-11-25",
      pi_expiration_date: "2023-10-06",
      contact_number: "26612345",
      relationship_manager_id: 2,
      remarks: "SI",
      email: "jebb.wong@jvsakk.com",
      hkid: "Z003352(A)",
      account_types: [1, 2, 3, 4, 5, 6, 7],
      banks: [{ id: 0, bank_id: 3, account_number: "0127121009668" }],
      controlling_persons: [
        {
          name: "Mary Chan",
          email: "jebb.wong@jvsakk.com",
          identity_document: "Z003352(A)",
          date_of_birth: "1980-11-25",
          person_type_id: 1,
        },
      ],
    };

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientUserStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-client/fetchUserClient", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          clientData.value = response.data.client;
        })
        .catch((error) => {
          console.log("error when fetching client", error);
          if (error.response.status === 404) {
            clientData.value = undefined;
          }
        });
    };

    const documentCategoryOptions = ref([]);
    const category_id = ref(null);

    documentCategoryOptions.value = [
      {
        id: 3,
        category_name: "Bank Document",
      },
    ];

    category_id.value = 3;

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", { document_category: true })
        .then((response) => {
          // documentCategoryOptions.value = response.data.document_categories;
          // category_id.value = response.data.document_categories[0].id;
        });
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    return {
      clientData,
      refetchData,
      documentCategoryOptions,
      category_id,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.info-wrap {
  .col-12 {
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
  }

  .tag-list {
    a {
      margin-right: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
.drop-btn {
  margin-top: 5px;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  background-color: rgba(148, 200, 187, 0.2);

  svg {
    transition: all 300ms ease;
    top: 2px;
    right: 0px;
  }

  &.not-collapsed {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.drop-item {
  display: block;
  margin-top: 5px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid rgba(189, 189, 189, 0.5);
}
</style>

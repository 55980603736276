import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUserClients(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("/client/list", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchUserClient(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get(`/client/?id=${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addUserClient(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/client", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteUserClient(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .delete("/client", { params: queryParams })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateSort(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .put("/client", data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
